<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="调拨单编号" >
                <el-input v-model="search.diaoBoDanBH" size="small" />
            </x-search-item>
            <x-search-item label="申请时间" textWidth="138px" hasEnd>
                <el-date-picker v-model="search.shenQingSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.shenQingSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="状态" >
                <el-input v-model="search.zhuangTai" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="diaoBoDanBH" label="调拨单编号" />
            <el-table-column width="180" prop="caoZuoRen" label="操作人" />
            <el-table-column width="180" prop="shenQingSJ" label="申请时间" />
            <el-table-column width="180" prop="chuKuSJ" label="出库时间" />
            <el-table-column width="180" prop="caoZuoSJ" label="操作时间" />
            <el-table-column width="180" prop="shenQingHeJi" label="申请合计" />
            <el-table-column width="180" prop="yingShouHeJi" label="应收合计" />
            <el-table-column width="180" prop="yingShouJinE" label="应收金额" />
            <el-table-column width="180" prop="shiShouHeJi" label="实收合计" />
            <el-table-column width="180" prop="shiShouJinE" label="实收金额" />
            <el-table-column width="180" prop="zhuangTai" label="状态" >
                <x-dict-show slot-scope="{row}" :code="row.zhuangTai" dictType="T_HUO_LIU_STATUS" />
            </el-table-column>
            <el-table-column width="180" prop="jvShouHeJi" label="拒收合计" />
            <el-table-column width="180" prop="jvShouJinE" label="拒收金额" />
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 详情页 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import {query} from "@/service/hlgl/DiaoBoDan";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/hlgl/dbd/DiaoBoDanDetail";

    export default {
        name: "DiaoBoDanQuery",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = query;
            return {
                search: {
                    diaoBoDanBH: '',
                    shenQingSJBegin: '',
                    shenQingSJEnd: '',
                    zhuangTai: '',
                    confirm: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>